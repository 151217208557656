.chat-opener {
  position: fixed;
  width: 68px;
  height: 68px;
  border-radius: 60px 60px 60px 60px;
 /*  background: #5086e9; */
  bottom: 30px;
  right: 16px;
  cursor: pointer;
  z-index: 9999;
  padding: 15px 10px 10px;
  /* content: url(https://hugo.legal/assets/imgs/chat-icon.svg); */
}
/* chat btn future
.chat-opener{
  display: flex;
}

.chat-opener::after{
  content:"Start";
  font-size: 13px;
  font-weight: bold;
  color: white;
}

.chat-opener::before{
  content: url(https://hugo.legal/assets/imgs/chat-icon.svg);
    width: 24px;
    margin-left: 10px;
    margin-right: 10px;
}
*/

.chat-wrapper {
  font-family: sans-serif;
  background-color: #fff;
  border: 1px solid #eee;
  width: 480px;
  height: 80%;
  position: fixed;
  right: 85px;
  bottom: 60px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  z-index: 99999;
  padding: 20px;
}

.app-header {
  border-bottom: 1px solid #d3d3d3;
  padding: 10px;
  text-align: center;
  opacity: 1;
  display: block;
  transition: opacity .1s display 2s;
}
.app-header.scroll{
  opacity: 0;
  display: none;
}

.close-icon {
  color: #f5f9ff;
  float: right;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.close-icon::before {
  font-size: 23px;
}
.menu-icon {
  color: #171e3b;
  float: left;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.menu-icon::before {
  font-size: 23px;
}
code {
  word-break: break-all;
}
.chat-body {
  display: flex;
  height: calc(100% - 98px);
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 40px;
}

.message-wrapper-row {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  flex-direction: column;
}
.message-wrapper-row img {
  margin-right: 6px;
}

#message-wrapper-decisions {
  margin-left: 35px;
}
#message-wrapper-booking {
  margin-left: 45px;
  margin-top: -35px;
}
.booking-type {
  margin-top: -65px;
}
.message-wrapper-user-message,
.message-wrapper-user {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 10px;
}

.message-wrapper-user-message {
  border-radius: 0.75rem 0.75rem 0 0.75rem;
  background-color: #f0f8ff;
  font-size: .845rem;
  border: 1px solid #cee1f1;
  max-width: 20.4rem;
}
.message {
  border-radius: 0.75rem 0.75rem 0.75rem 0;
  padding: 0.75em;
  background-color: #eee;
  display: block;
  border: none;
  font-size: 0.835rem;
  line-height: 1.1875rem;
  max-width: 20.4rem;
  width: -webkit-max-content;
  width: max-content;
  min-height: 0.75rem;
}
.message-solo.active {
  background: none;
  font-size: 17px;
  line-height: 1.4;
}

.chat-footer {
  width: 92%;
  font-size: 16px;
  bottom: 12px;
}

.file-drop-zone {
  flex: 1 1;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin: 0 30px;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}
.img {
  display: block;
  width: auto;
  height: 100%;
  min-width: 0;
}

.google-autocomplete-input {
  width: 95%;
}

.waiting-container{
  height: 600px;
  width: 100%;
  display: flex;
}
.waiting {
  margin: auto;
  will-change: transform;
  display: table;
  position: relative;
  -webkit-animation: 2.5s, local(bulge) ease-out infinite;
  animation: 2.5s, local(bulge) ease-out infinite;
}

.waiting::after {
  height: 10px;
  width: 10px;
  left: -8px;
  bottom: -8px;
}

.waiting span {
  height: 11px;
  width: 11px;
  float: left;
  margin: 0 2px;
  background-color: #5086e9;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.waiting span:first-of-type {
  -webkit-animation: 2s, local(blink) 0.66s infinite;
  animation: 2s, local(blink) 0.66s infinite;
}

.waiting span:nth-of-type(2) {
  -webkit-animation: 2s, local(blink) 1.333s infinite;
  animation: 2s, local(blink) 1.333s infinite;
}

.waiting span:nth-of-type(3) {
  -webkit-animation: 2s, local(blink) 1.9999s infinite;
  animation: 2s, local(blink) 1.9999s infinite;
}

.formInput {
  border-radius: 0 !important;
  border-color: #D7DFE9 !important;
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 60px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 60px;
  color: #7E858E;
  border: 1px solid #D7DFE9;
  padding: 1rem;
  border-radius: 10px !important;
  margin-bottom: 10px;
}


textarea:focus, input:focus{
  outline: none;
}

.formInput_error {
  font-family: 'Montserrat';
  font-size: 1rem;
  line-height: 60px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 60px;
  border: 1px solid #FF0B37;
  background-color: #ff0b3709;
  color: #7E858E !important;
  padding: 1rem;
  border-radius: 10px;

}
.red {
  color:#FF0B37;
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: 500;
  width: 100%;
    margin-bottom: 20px;
}

.formMulti {
  border-radius: 0 !important;
  border-color: #ced4da !important;
  font-family: 'Poppins';
  font-size: 1rem;
  line-height: 1.1875rem;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100px;
  border: 1px solid #dce2ed;
  padding: 1rem;
  border-radius: 0.5rem !important;
  margin-bottom: 130px;
  position: absolute;
}
.react-creatable__indicators{
  display: none;
}

.file-drop-zone {
  background: #e0e0e0;
  border-radius: 0;
  line-height: 4rem;
  height: 4rem;
  text-align: center;
  color: #666666;
  cursor: pointer;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
  border: 1px dashed #999;
}
.chat-wrapper .container {
  width: 100%;
  padding: initial;
}
.chat-wrapper .row {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-size: 9px;
  line-height: 11px;
}

#upload-submit-button {
  background: #5086e9;
  color: white;
  border: honeydew;
  width: 200px;
  height: 2rem;
  border-radius: 0.4rem;
  margin-bottom: 1px;
  cursor: pointer;
}

#upload-submit-button .fa-share:before {
  content: 'Upload' !important;
  font-family: sans-serif;
  text-transform: capitalize;
}

#message-wrapper-decisions {
  padding: 10px;
  margin-left: 30px;
  padding-top: 0;
}

#message-wrapper-decisions .btn {
  background: #ffffff;
  color: #5086e9;
  border: 2px solid #5086e9;
  cursor: pointer;
  line-height: 1.375rem;
  border-radius: 0.625rem;
  width: 100%;
  margin-right: 0.5rem;
  font-size: 0.8rem;
  width: auto;
  max-width: 100%;
  padding: 0.425rem;
  height: auto;
  margin-right: 0.4rem;
  min-width: 6.5rem;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: inline-block;
  text-align: center;
}

.chat-wrapper #multiple-choice {
  margin-left: 30px;
  padding: 10px;
  padding-top: 0;
}
.chat-wrapper #multiple-choice .btn-primary {
  height: 3rem;
  border: #5086e9 2px solid;
  margin-left: 10px;
  background: #5086e9;
  color: white;
  min-width: 3rem;
  border-radius: 0 13px 13px 0;
  font-size: 15px;
  cursor: pointer;
}

.chat-wrapper #multiple-choice .btn-primary:hover {
  background: gray;
  border-color: gray;
}

.chat-wrapper #multiple-choice .fa-share:before {
  content: '\f1d8';
  transform: rotate(30deg);
  display: block;
  margin-bottom: 4px;
  margin-right: 4px;
}

.submit-choice-btn {
  height: 3rem;
  border: #5086e9 2px solid;
  margin-left: 10px;
  background: #5086e9;
  color: white;
  min-width: 3rem;
  border-radius: 0 13px 13px 0;
  font-size: 15px;
  cursor: pointer;
}
.submit-choice-btn:hover {
  background: gray;
  border-color: gray;
}
.submit-choice-icon:before {
  content: '\f1d8';
  transform: rotate(30deg);
  display: block;
  margin-bottom: 4px;
  margin-right: 4px;
}

.chat-wrapper select {
  padding: 10px;
  border: 1px solid;
  cursor: pointer;
  max-width: 268px;
}

.wrapper select:active,
.chat-wrapper select:hover {
  border: 1px solid #5086e9;
}

.css-yk16xz-control {
  border-color: #037bff !important;
  border-radius: 13px;
  width: 11.5rem;
  height: 3rem;
  border-width: 1px;
  border-radius: 13px 0 0 13px;
  cursor: pointer;
  font-size: 14px;
  line-height: 100%;
}

.google-autocomplete-input div:nth-child(1) {
  width: 100%;
}
.message-wrapper-block {
  padding: 10px;
  margin-left: 30px;
  padding-top: 0;
  display: flex;
}

.multiple-choice-selector{
  width: 100%;
}
.multiple-choice-selector .react-select__control {
  border-color: #037bff;
    border-radius: 13px;
    width: 100%;
    height: 3rem;
    border-width: 1px;
    border-radius: 13px 0 0 13px;
    cursor: pointer;
    font-size: 15px;
    line-height: 100%;
    padding-left: 15px;
    padding-right: 11px;
    background: aliceblue;
  border-radius: 23px;
  border-color: #c2dffe;  
}



.react-select__control {
  height: 3rem;
}
.formInput address-select__control {
  border-radius: 0 !important;
  border-color: #D7DFE9 !important;
  font-family: 'Poppins';
  font-size: 1rem;
  line-height: 60px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 60px;
  color: #D7DFE9;
  border: 1px solid #D7DFE9;
  padding: 1rem;
  border-radius: 10px !important;
  margin-bottom: 10px;
}

.address-select__menu-list {
  width: 100% !important;
}

.chat__input__button {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-40%);
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}
.chat__input__button::before {
  content: url(https://hugo.legal/assets/imgs/chat-send-active.svg);
  width: 1.875rem;
}





.form-control {
  border-radius: 13px;
  width: 83%;
  height: 3rem;
  border-width: 1px;
  cursor: pointer;
  font-size: 16px;
  line-height: 100%;
  padding-left: 15px;
  padding-right: 11px;
  background: aliceblue;
  border-radius: 23px;
  border-color: #c2dffe;
}

.form-control-2 {
  border-radius: 10px;
  width: 83%;
  height: 3rem;
  border-width: 1px;
  cursor: pointer;
  font-size: 16px;
  line-height: 100%;
  padding-left: 15px;
  padding-right: 11px;
  background: aliceblue;
  border-radius: 23px;
  border-color: #c2dffe;
}

.input-currency {
  background: none !important;
  border: none !important;
  position: absolute;
  right: 5%;
  top: 0;
  bottom: 0;
  height: 60px;
}
@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}
@keyframes blink {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@media only screen and (max-width: 566px) {
  .chat-wrapper {
    width: 100%;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}
@media only screen and (max-width: 361px) {
  .chat-wrapper select {
    max-width: 174px;
  }
}

/* The side navigation menu */
.sidenav {
  height: calc(100% - 65px);
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ff5566;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.9s;
  display: flex;
    flex-direction: column;
    overflow: hidden;
}
.hidden{
  display: none !important;
}
.closed{
  width: 0;
    padding-right: 0 !important;
    display: none;
}
.icon-open{
  transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
}

.menu-item {
  color: white;
  text-align: left;
  padding-left: 50px;
}

.progress_url {
  color: black;
  float: left;
  width: 80%;
  padding-left: 15px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.copy-icon {
  color: #fff !important;
  border-color: #fff !important;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: white;
}

#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.menu-button {
  height: 52px;
  line-height: 22px;
  padding: 0px 20px 0 15px;
  color: white;
  font-family: 'Montserrat';
  font-size: 16px;
  background: none;
  border: none;
  text-align: left;
}






.chat_button {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}
.default {
  color: #007aff;
  float: left;
  height: 52px;
  line-height: 22px;
  background: white;
  border: 1px solid #007aff;
  width: fit-content;
}

.default:hover {
  background: #e7e7e7;
}












.chat-opener {
  width: auto;
  height: 30px;
  line-height: 27px;
  border-radius: 4px;
  text-align: center;
  content: none;
  display: flex;
  padding: 0;
  right: 40px;
  border: 4px solid white;
  bottom: 50px;
  /* background: #007aff; */
  content: "Preview";
}
.chat-opener::before{
    /* content: url(https://hugo.legal/assets/imgs/chat-icon.svg); */
    content: "TEST";
    width: 40px;
    margin-left: 13px;
    margin-right: 10px;
}
.chat-opener.scrolled::after{
  content:"Loslegen!";
   animation-name: hugochatbtn;
  animation-duration: .27s;
  width: 100px;
  opacity: 1;
  color: white;
}




.chat__next__button {
  position: relative;
  right: 0;
  left: 0;
  margin: auto;
  padding: 0;
  margin: 0;
  border: none;
  background: #032097;
  border-radius: 10px;
  height: 60px;
  line-height: 60px;
  width: 100%;;
  margin-top: 10px;
  color: white;
  font-weight: 600;
    font-family: 'Montserrat';
}
.chat__next_secondary__button {
  position: relative;
  right: 0;
  left: 0;
  margin: auto;
  padding: 0;
  margin: 0;
  border: none;
  background: #F7DDDA;
  border-radius: 10px;
  height: 60px;
  line-height: 60px;
  width: 100%;;
  margin-top: 10px;
  color: #032097;
  font-weight: 600;
    font-family: 'Montserrat';
}

.chat__edit__button{
  position: relative;
  right: 0;
  left: 0;
  margin: auto;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 10px;
  height: 60px;
  line-height: 60px;
  width: 100%;;
  margin-top: 10px;
  background: #F7DDDA;
  color: #032097;
}


.chat_edit_h{
  font-weight: normal;
  color: #7E858E;
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: .9rem;
  margin-top: 14px;
  margin-bottom: 2px;

}
.chat__edit__input{
  width: 100%;
    height: auto;
    border-radius: 10px;
    background: #F3F4F9;
    cursor: pointer;
    border: 1px solid #D7DFE9;
    color: #171E3B;
    text-align: left;
    position: relative;
    line-height: 60px;
    padding-left: 13px;
    line-height: 23px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: .95rem;
    padding-right: 3rem;
}
.chat__edit__input i{
  position: absolute;
  width: 20px;
  height: 20px;
  font-size: 20px;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.chat__not__button {
  position: relative;
  right: 0;
  left: 0;
  margin: auto;
  padding: 0;
  margin: 0;
  border: none;
  background: lightgray;
  border-radius: 10px;
  height: 50px;
  line-height: 50px;
  width: 100%;;
  bottom: 10px;
  color: white;
}
.chat__third__button {
  position: relative;
  right: 0;
  left: 0;
  margin: auto;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  border-radius: 10px;
  height: 50px;
  line-height: 50px;
  width: 100%;;
  bottom: 10px;
  color: blue;
}


.chat__radio__group {
  display: block;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}
.chat__radio__button {
  display: block;
 height: 30px;
 line-height: 30px; 
 margin-bottom:10px;
 padding-bottom: 0;
 position: relative;
}
.chat__radio__button i{
  position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: none;
    width: 20px;
    height: 20px;
    font-size: 1.6rem;
    
}

.chat__radio__group input[type="radio"] + label {
  background-color: white !important;
    line-height: 60px;
    height: 60px;
    text-align: left;
    border-radius: 10px;
    padding-left: 30px;
    border: 1px solid #BBC2E7;
    color: #032097;
    font-weight: bold;

}
.chat__radio__group input[type="radio"]:checked + label {
  background-color: #f3f3f3 !important;
    border: 1px solid;
}
.chat__radio__group input[type="radio"]:checked + label i {
 display: block;
}




.chat__radio__input__button[type="radio"] {
  display: none;
}


.chat__radio__span__button[type="radio"]:checked{
 
  background: pink !important;


}
.chat__radio__span__button{
  height: 30px;
  line-height: 30px; 
  padding-left: 5px;


}

@media (max-width: 1007px) {
     .chat-opener.scrolled::after{
    content:"Loslegen!";
     animation-name: hugochatbtn;
    animation-duration: .27s;
    width: 100px;
    opacity: 1;
    line-height: 2;
    color: white;
  }
  .chat-opener {
    height: 72px;
  }
  .chat-opener::before{
   /*  content: url(https://hugo.legal/assets/imgs/chat-icon.svg); */
      width: 33px;
    margin-left: 10px;
    margin-right: 12px;
	} 
  
}


@keyframes hugochatbtn {
  from {width: 0px; opacity: 0;}
  to {width: 100px; opacity: 1;}
}

.chat-wrapper {
    border: 1px solid #007aff;
}

.chat-wrapper h1{
  font-size: 2.1rem;
  font-weight: 700 !important;
  margin-top: 1rem;
  line-height: 1.25;
}
.chat-wrapper {
    width: 500px;
    right: 20px;
    bottom: 27px;
    border: 1px solid #007aff;
    height: calc(100% - 95px);
}
.message{
	background-color: #fbfbfb;
    border: 1px solid lightgray;
  font-size: .845rem;
}
.message-solo{
  background: none;
  font-size: 17px;
  line-height: 1.4;
}

.message-solo h1{
  color: #171E3B;
  font-family: 'Montserrat';
}
.message-solo p{
  color: #171E3B;
  font-family: 'Montserrat';
  font-size: 1.2rem;
}
.chat-body {
    height: calc(100% - 136px);
  	margin-top: 7px;
  	padding-bottom: 10px;
}
.chat-footer {
  width: calc(86%);
    font-size: 16px;
    bottom: 2rem;
    margin: auto;
    right: 7%;
    left: 7%;
    margin-top: 10px;
}
.step-back{
  position: absolute;
  left: 3px;
  width: 46px;
  bottom: 37px;
}
.fa-footer{
  height: 50px !important;
  line-height: 51px !important;
}

.message-wrapper-row img {
    width: 100%;
}
.app-header{
    border-bottom: 1px solid #d3d3d3;
    padding: 0px;
    text-align: center;
    height: 78px;
    border: none;
    z-index: 9999999;
    display: block;
    position: absolute;
    width: 100%;

}

.close-icon {
    height: 26px;
    width: 26px;
}
.close-icon:before {
    font-size: 26px;
}
.menu-btn{
  background-color: #EECDC9;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  top: 8px;
  z-index: 1111;
  cursor: pointer;
}
.menu-icon {
    height: 26px;
    width: 26px;
    color: #032097;
    -webkit-transition: -webkit-transform .3s ease-out;
    -ms-transition: -ms-transform .3s ease-out;
    transition: transform .3s ease-out; 
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 5px;
    position: absolute; 
    z-index: 11111111;
    background-color: #EECDC9;
}
.menu-icon:before {
    font-size: 25px;
}


.chat_button {
    border-radius: 50px;
}
.sidenav {
  max-width: 100%;
  position: absolute;
  left: 0px;
  right: auto;
  background-color: #032097;
  transition: none;
  top: 1px;
  padding-left: 24px;
  padding-top: 112px;
  height: 633px;
}
.sidenav .closebtn{
	top: 14px;
}
.menu-item {
       font-weight: bold;
    margin-top: 59px;
    padding-left: 42px;
  font-size: 18px;
  display: block !important;
}
.copy-icon {
  margin-left: 15px;
  cursor: pointer;
}
.google-autocomplete-input div:first-child {
    width: initial;
    line-height: 22px;
    line-height: 22px;
}


.chat-body.scroll .buttons{
  visibility: visible;
}

@media (max-width: 768px) { 
  .app-header{
    background: white;
    box-shadow: 0px 3px 15px #ededed;
  }
  .chat-wrapper {
    width: 102%;
    right: -1%;
    bottom: 0;
    height: 100%;
    padding: 0;
    display: block;
    user-select: none;
	} 
  .chat-body {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 160px;
	}
  .chat-body.scroll{
    height: 100%;
    padding-top: 70px;
    padding-bottom: 160px;

  }
 
  
  .message-wrapper-row{
  	margin-left: 5%;
    margin-right: 5%;
    margin-top: 1.2rem;
  }
  .message-wrapper-element{
  	padding: 2%;
  }
  .message-wrapper-user-message {
    margin-right: 5%;
    max-width: 20.4rem;
	}

  #message-wrapper-decisions {
    margin-left: 9%;
	}
    
}


.progress-container{
  position: absolute;
  top: 8px;
  width: calc(100% - 70px);
  right: 0;
  z-index: 99999999;
}
.progress{
  display: block;
  background: #032097;
  height: 9px;
  top: -1px;
  position: absolute;
  left: -1px;
}
.progress-indicator{
  display: block;
  background: #EECDC9;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 8px;
}
.left-button{
  position:absolute;
  right: 1.8em;
  bottom: 17px;
  color: #032097;
  font-size: 2.5em;
}
.right-button{
  position:absolute;
  right: 0.6em;
  bottom: 17px;
  color: #EECDC9;
  font-size: 2.5em;
}
.progress-control{
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 70px;
  opacity: 1;
  transition: opacity .1s;
}
.progress-control.scroll{
  opacity: 0;
}
.progress-info{
  position: absolute;
    left: 1.4rem;
    top: 25px;
    color: #032097;
    font-size: 16px;
    font-family: 'Montserrat';
}
.progressheader{
  color: #032097;
  font-family: 'Montserrat';
  font-size: 16px;
}
.widget_notification{
  width: 100%;
  margin-top: 10px;
}

.widget_notification p{
  background: #F3F4F9;
    /* display: block; */
    padding: 18px;
    border-radius: 10px;
    font-style: italic;
    color: #171E3B;
    font-family: 'Montserrat';
    font-size: 1rem;
    line-height: 1.35rem;

}
.modalcontainer{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: whitesmoke;
  height: 80%;
  border-radius: 10px;
  padding: 1rem;
  width: 90%;
  z-index: 111111;
  font-family: 'Montserrat';
    font-size: 1.1rem;
}
.modal-close{
  font-size: 22px;
    width: 100%;
    text-align: right;
    padding: 6px;
    padding-bottom: 26px;
    color: gray;
    
}
.modallink{
  font-style: normal;
  color: #171E3B;
  font-family: 'Montserrat';
  font-size: 1rem;
  border-bottom: 1px solid;
  font-weight: 600;
}

.editmode{
  background: #f5f5f5;
}
.progresseditmode{
  opacity: .3;
  filter: grayscale(1);
  user-select: none;
  position: absolute;
    top: 0;
}




.share-input{
  width: calc(100% - 60px);
  border-radius: 10px 0 0 10px !important;
}
.share-input-btn{
  width: 60px;
  height: 60px;
  margin: 0;
  border-radius: 0 10px 10px 0;
  text-align: center;
}
.share-input-btn i{
 line-height: 60px;
}
.share-emaillist{
  font-family: 'montserrat';
  border-radius: 3px;
  display: flex;
  position: relative;
  line-height: 30px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 30px;
  background: lightgray;
    margin: 5px;
    color: #454545;
    border-radius: 5px;
    border: 1px solid lightgray;
    font-size: 13px;
}
.share-emaillist:hover{
  background-color: #F7DDDA;
}
.share-emaillist i{
  position: absolute;
  right: 10px;
  line-height: 30px;
}
.input-textarea{
  margin-top: 10px;
  width: 100%;
  border: 1px solid #D7DFE9;
  border-radius: 10px;
  padding: 10px;
  font-family: 'montserrat';
  font-size: 14px;
}

.buttons{
  position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding-left: 7%;
    padding-right: 7%;
    background: white;
    padding-bottom: 18px;
   
}

.app{
  z-index: 999999;
  background: white;
  position: relative;
}
.app-promo{
  display: none;
}

@media (min-width: 768px) { 

    .app-promo{
      display: flex;
      height: 100%;
      background-color: #F7DDDA;
      position: relative;
    }
    .app-promo img{
    width: 88%;
    filter: grayscale(1);
    }
    .app-promo-area{
      width: 80%;
      margin: auto;
      left: 0;
      right: 0;
      height: 93%;
      display: flex;
      flex-flow: wrap;
      padding-top: 70px;
      flex-direction: column;
    }
    .app-promo-text {
      position: absolute;
      width: 100%;
      padding: 2rem;
      z-index: 1;
      height: 100%;
      vertical-align: bottom;
      display: flex;
      flex-direction: column-reverse;
    }
    .chat-wrapper{
      height: 100%;
      top: 20px;
      bottom: 20px;
      width: 100%;
      padding: 0;
      border: none;
      right: 0;
      background: none;
      user-select: none;
    }
    .chat-wrapper h1{
      font-size: 1.8rem;
      font-weight: 700 !important;
      margin-top: 1rem;
      line-height: 1.35;
    }
    .progress-container{
      left: initial;
      right: 0;

    }
    .progress-info{
     left: 0;
    }
    .chat-footer{
      width: 100%;
      padding: 10px;
    }
    .chat-body{
      margin-top:0;
      position: fixed;
      width: 100%;
      right: 0;
      top: 9px;
    }
    .left-button{
      right: 2.5em;
    }
    .right-button{
      right: 1.5em;
    }
    .sidenav{
      position: fixed;
    top: 8px;
    height: 100%;
    }
}

@media (min-width: 768px) { 

  .app-promo{
    width: 330px;
  }

  .buttons{
    right: 0;
    left:auto;
    position: fixed;
    padding-top: 10px;
    right: 20px;
    width: calc(100% - 330px - 20px);
    bottom: 0;
    height: 160px;
    padding-right: calc((100% - 330px - 400px + 20px)/2);
    padding-left: calc((100% - 330px - 400px  + 20px)/2);
  }

  .progress-container{
    width: calc(100% - 330px);
    padding-left: calc((100% - 330px - 400px + 20px)/2);
  }
 
  .chat-body{
    height: calc(100% - 9px);
    padding-bottom: 160px;
    padding-right: calc((100% - 330px - 400px)/2);
    padding-left: calc(330px + (100% - 330px - 400px)/2);
    padding-top: 10%;
  }
  .sidenav{
  width: 330px;
  }
}

@media (min-width: 1100px) { 

  .app-promo{
    width: 420px;
  }

  .chat-wrapper h1{
    font-size: 1.8rem;
    font-weight: 700 !important;
    margin-top: 1rem;
    line-height: 1.35;
  }

  .buttons{
    width: calc(100% - 420px - 20px);
    padding-top: 10px;
    right: 20px;
    bottom: 0;
    height: 160px;
  padding-left: calc(13% + 10px);
  padding-right: calc(15% + 10px);
  }
  .progress-container{
    width: calc(100% - 420px);
    padding-left: calc(13% + 9px)
  }
 
  .chat-body{
    height: calc(100% - 9px);
    padding-right: 15%;
    padding-left: calc(420px + 13%);
    padding-top: 10%;
  }

  .sidenav{
  width: 420px;
  }
}


 /* editor specific */
.app{
  position: fixed;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.header-options{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9999999;
}
.client-controls i{
  position: relative;
  width: 50px;
  line-height: 50%;
  font-size: 14px;
  padding: 13px;
  cursor: pointer;
}
.client-controls{
  position: fixed;
  left: 0;
  top: 70%;
  width: 70px;
  z-index: 99999999;
}
@media (max-width: 768px) { 
  .mobile-hide{
    display: none;
  }
  .client-controls i{
    position: relative;
    width: 100%;
    font-size: 17px;
    cursor: pointer;
    color: #a8a8a8;
    padding-top: 25px;
    padding-bottom: 12px;
  }
  .client-controls{
    position: fixed;
    width: 68px;
    z-index: 9999999999;
    background: #cecece30;
    bottom: 0;
    height: 115px;
    padding-top: 6px;
    top: initial;
  }
}
